import React, { useEffect } from 'react'
import {
	removeEmail,
	removeRedirectUrl,
	setTempProfile
} from '../../helpers/database'
import { registerUser } from '../../services/auth'
import { getProfile } from '../../services/user'
import { navigate } from 'gatsby'
import { useQuery } from '@tanstack/react-query'
import Splash from '../../splash'

const FinishRegistrationPage = ({ email, bookingId, eventId, redirectUrl }) => {
	const { data, isLoading, error } = useQuery(['profile'], getProfile)

	const handleLogin = async () => {
		if (!email) {
			// console.log('email', email)
			return await navigate('/login/not-valid/')
		}
		const _user = await registerUser(email)
		if (!_user) {
			return await navigate('/login/not-valid/')
		}
		if (Object.keys(_user).length === 0) {
			return await navigate('/')
		}
		removeEmail()
	}

	const handleProfile = async () => {
		if (isLoading) return
		if (error) {
			// console.log('error', error)
			return await navigate('/login/not-valid/')
		}
		// console.log('data', data)

		const missingRequired =
			!data?.name ||
			data?.name === '<anonymous>' ||
			!data?.surname ||
			!data.nickname ||
			!data.acceptedTerms

		// User needs to create Account
		if (missingRequired) {
			return navigate('/')
		}

		setTempProfile(data || {})

		// User has logged in
		if (!!bookingId) {
			return navigate(`/start?bookingId=${bookingId}`)
		} else if (!!eventId) {
			return navigate(`/start?eventId=${bookingId}`)
		} else if (!!redirectUrl) {
			removeRedirectUrl()
			return navigate(redirectUrl)
		} else {
			return navigate('/')
		}
	}

	useEffect(() => {
		handleProfile().then(() => null)
	}, [data, error, isLoading])

	useEffect(() => {
		handleLogin().then(() => null)
	}, [])

	return <Splash isLoading={true} />
}

export default FinishRegistrationPage
