import React from 'react'
import Layout from '../../layout'
import FinishRegistrationPage from '../../pagesComponents/login/finish-registration'
import { setBookingId, setRedirectUrl } from '../../helpers/database'

const FinishRegistration = ({ location }) => {
	const params = new URLSearchParams(location.search)
	const email = params.get('email')?.toString()?.replace(/ /g, '+') || null
	const bookingId = params.get('bookingId')?.toString() || null
	const eventId = params.get('eventId')?.toString() || null
	const redirectUrl = params.get('redirect')?.toString() || null
	if (!!bookingId) {
		setBookingId(bookingId)
	}
	if (!!redirectUrl) {
		setRedirectUrl(redirectUrl)
	}
	return (
		<Layout theme="light" title="Registration Complete">
			<FinishRegistrationPage
				email={email}
				bookingId={bookingId}
				eventId={eventId}
				redirectUrl={redirectUrl}
			/>
		</Layout>
	)
}

export default FinishRegistration
